export function wrapForm() {
  var form = document.getElementById('wpcf7-f66-o1');
  if (form) {
    var wrapper = document.createElement('div');
    wrapper.className = 'footer-form';
    form.parentNode.insertBefore(wrapper, form);
    wrapper.appendChild(form);
    form.style.display = 'block';
  }
}

export function setupHamburgerMenu() {
  var hamburgerButton = document.getElementById('hamburger-button');
  var mobileNav = document.getElementById('mobile-nav');
  var menuLinks = document.querySelectorAll('.menu-link a'); // Selecione todos os links do menu

  if (hamburgerButton && mobileNav) {
    hamburgerButton.addEventListener('click', function () {
      this.classList.toggle('active');
      mobileNav.classList.toggle('open');
    });

    // Adicione um evento de clique para cada link do menu
    menuLinks.forEach(function (link) {
      link.addEventListener('click', function () {
        // Feche o menu móvel ao clicar em um link
        hamburgerButton.classList.remove('active');
        mobileNav.classList.remove('open');
      });
    });
  }
}

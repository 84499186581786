export function setupScrollUp() {
  const scrollUp = document.getElementById('scroll-up');

  scrollUp.addEventListener('click', function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  window.addEventListener('scroll', function () {
    if (window.scrollY >= 600) {
      scrollUp.style.display = 'flex';
      scrollUp.classList.add('show-scroll');
    } else {
      scrollUp.style.display = 'none';
      scrollUp.classList.remove('show-scroll');
    }
  });
}

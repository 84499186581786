export function setupSubmitButtonHandler() {
  var cf7SubmitButtons = document.querySelectorAll('.wpcf7-submit');

  cf7SubmitButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      button.dataset.originalText = button.value;
      button.value = '';
      var spinner = button.nextElementSibling;
      if (spinner) {
        spinner.style.visibility = 'visible';
      }
    });
  });

  function restoreSubmitButton(button) {
    if (button && button.dataset.originalText) {
      button.value = button.dataset.originalText;
      var spinner = button.nextElementSibling;
      if (spinner) {
        spinner.style.visibility = 'hidden';
      }
    }
  }

  document.addEventListener(
    'wpcf7mailsent',
    function (event) {
      var form = event.target;
      var submitButton = form.querySelector('.wpcf7-submit');
      restoreSubmitButton(submitButton);
    },
    false,
  );

  document.addEventListener(
    'wpcf7invalid',
    function (event) {
      var form = event.target;
      var submitButton = form.querySelector('.wpcf7-submit');
      restoreSubmitButton(submitButton);
    },
    false,
  );

  document.addEventListener(
    'wpcf7mailfailed',
    function (event) {
      var form = event.target;
      var submitButton = form.querySelector('.wpcf7-submit');
      restoreSubmitButton(submitButton);
    },
    false,
  );
}

function checkCookiePopup() {
  var cookiePopup = document.querySelector('.cookie');
  var cookieAcceptedTime = localStorage.getItem('cookieAcceptedTime');
  var now = new Date().getTime();
  var twentyFourHours = 24 * 60 * 60 * 1000;

  if (!cookieAcceptedTime || now - cookieAcceptedTime > twentyFourHours) {
    cookiePopup.classList.remove('hide');
    cookiePopup.style.display = 'block';
  }
}

document.addEventListener('DOMContentLoaded', function () {
  var cookiePopup = document.querySelector('.cookie');
  var acceptButton = document.querySelector('.button__cookie');

  acceptButton.addEventListener('click', function () {
    closeAndStoreCookiePopup();
  });

  function closeAndStoreCookiePopup() {
    var now = new Date().getTime();
    localStorage.setItem('cookieAcceptedTime', now);
    cookiePopup.classList.add('hide');
    setTimeout(function () {
      cookiePopup.style.display = 'none';
    }, 500);
  }

  checkCookiePopup();
});

import './scss/style.scss';

import './js/cookie.js';

import { wrapForm } from './js/formWrapper.js';
import { setupHamburgerMenu } from './js/hamburgerMenu.js';
import { setupScrollUp } from './js/scrollUp.js';
import { setupSubmitButtonHandler } from './js/submitButtonHandler.js';

document.addEventListener('DOMContentLoaded', function () {
  
  setupSubmitButtonHandler();
});

window.onload = function () {
  wrapForm();
  setupHamburgerMenu();
};

setupScrollUp();